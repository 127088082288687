import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { SignUpLink } from '../SignUp';
import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import { PasswordForgetLink } from '../../Authentication/PasswordForget';
import SignInButton from '../SignIn/SignInButton';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

const SignInPage = () => (
  <div>
    <SignInForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.EXHIBIT);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === '' || email === '';

    return (
      <Grid textAlign='center' style={{ height: '100vh' }} verticalAlign='top'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header as='h2' color='blue' textAlign='center'>
            Please Sign In
          </Header>

          <Form size='large' onSubmit={this.onSubmit}>
            <Segment>
              <Form.Input
                fluid
                icon='user'
                iconPosition='left'
                name='email'
                value={email}
                onChange={this.onChange}
                type='text'
                placeholder='E-mail Address'
              />
              <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                name='password'
                value={password}
                onChange={this.onChange}
                type='password'
                placeholder='Password'
              />
            </Segment>
            <Button fluid color='blue' type='submit' disabled={isInvalid}>
              Sign In
            </Button>
          </Form>
          {error && <Message>{error.message}</Message>}
          <Message>
            <PasswordForgetLink />
            <SignUpLink />
          </Message>
        </Grid.Column>
      </Grid>
    );
  }
}
const SignInForm = compose(withRouter, withFirebase)(SignInFormBase);

export default SignInPage;
export { SignInForm, SignInButton };
