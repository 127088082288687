import React, { Component } from "react";
import FileUploader from "react-firebase-file-uploader";
import { withFirebase } from "../Firebase";

const INITIAL_STATE = {
  isUploading: false,
  progress: 0,
  fileURL: ""
};

class FileUpload extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleUploadStart = () => this.setState({ isUploading: true, progress: 0 });
  handleProgress = progress => this.setState({ progress });
  handleUploadError = error => {
    this.setState({ isUploading: false });
    console.error(error);
  };

  handleUploadSuccess = filename => {
    this.setState({ progress: 100, isUploading: false });
    this.props.firebase
      .image(filename)
      .getDownloadURL()
      .then(url => {
        this.setState({ fileURL: url });
        this.props.onFileUploaded(url, this.props.authUser);
      });
  };

  render() {
    const { firebase } = this.props;

    const css1 = `
        .inputFile {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        `;

    return (
      <div>
        {this.state.isUploading && <p>Progress: {this.state.progress}</p>}
        {/* {this.state.fileURL && <img src={this.state.fileURL} alt='file' />} */}
        <style type="text/css">{css1}</style>

        <label htmlFor="file" className="ui icon right labeled button green">
          Choose a File
          <i aria-hidden="true" className="upload icon"></i>
        </label>

        <FileUploader
          name="file"
          className="inputFile"
          id="file"
          accept="image/*"
          randomizeFilename
          storageRef={firebase.images()}
          onUploadStart={this.handleUploadStart}
          onUploadError={this.handleUploadError}
          onUploadSuccess={this.handleUploadSuccess}
          onProgress={this.handleProgress}
        />
      </div>
    );
  }
}

export default withFirebase(FileUpload);
