import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

const MakeAdminPage = () => (
  <div>
    <MakeAdminForm />
  </div>
);

const INITIAL_STATE = {
  email: '',
  error: null,
  message: '',
  loading: false,
};
class MakeAdminFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  handleDismiss = () => {
    this.setState({ message: null });
  };

  onSubmit = (event) => {
    const { email } = this.state;

    this.setState({ loading: true });
    this.props.firebase.makeAdmin(email).then(
      (result) =>
        this.setState({ message: result.data.message, loading: false }),
      (error) => this.setState({ error: error })
    );

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error, message, loading } = this.state;

    const isInvalid = email === '';

    return (
      <Grid verticalAlign='top' textAlign='center'>
        <Grid.Column style={{ maxWidth: 500 }}>
          <Header as='h2' color='blue' textAlign='center'>
            Make Admin
          </Header>
          <Segment textAlign='left'>
            <Form size='large' onSubmit={this.onSubmit}>
              <Form.Input
                fluid
                icon='user'
                iconPosition='left'
                name='email'
                value={email}
                onChange={this.onChange}
                type='text'
                placeholder='E-mail Address'
              />

              <Button
                fluid
                color='blue'
                type='submit'
                disabled={isInvalid}
                loading={loading}
              >
                Make Admin
              </Button>
            </Form>
            {error && <Message>{error.message}</Message>}
            {message && (
              <Message
                icon='thumbs up outline'
                header='Success!'
                content={message}
                onDismiss={this.handleDismiss}
              />
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

const MakeAdminForm = compose(withRouter, withFirebase)(MakeAdminFormBase);

export default MakeAdminPage;
export { MakeAdminForm };
