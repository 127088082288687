import React, { Component } from 'react';

import { AuthUserContext, withAuthorization } from '../Session';
import FileUpload from '../FileUploader';
import { withFirebase } from '../Firebase';

import placeholderImgMale from '../../assets/images/user-placeholder-male.jpg';
import placeholderImgFemale from '../../assets/images/user-placeholder-female.jpg';

//import { MakeAdminPage } from '../Admin';

import { Grid, Card, Icon, Image } from 'semantic-ui-react';

function coinToss() {
  const flip = Math.round(Math.random());
  return flip ? true : false;
}

const placeholderImg = coinToss() ? placeholderImgMale : placeholderImgFemale;

const INITIAL_STATE = {
  userId: '',
  imgURL: placeholderImg,
  loading: false,
};

class AccountPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onListenForImages() {
    this.setState({ loading: true });

    this.props.firebase.user(this.state.userId).on('child_added', (data) => {
      if (data.key === 'avatarURL') {
        this.setState({ imgURL: data.val() });
      } else {
        this.setState({ imgURL: null, loading: false });
      }
    });
  }

  componentDidMount() {
    this.onListenForImages();
  }

  componentWillUnmount() {
    this.props.firebase.user().off();
  }

  onFileUploaded = (url, user) => {
    this.props.firebase
      .user(user.uid)
      .set({
        ...user,
        avatarURL: url,
      })
      .then(this.setState({ imgURL: url, userId: user.uid }));
  };

  render() {
    return (
      <React.Fragment>
        <AuthUserContext.Consumer>
          {(authUser) => (
            <div style={{ marginTop: '20px' }}>
              <Grid centered>
                <Grid.Row>
                  <Card>
                    {
                      <Image
                        src={this.state.imgURL || authUser.avatarURL}
                        wrapped
                        ui={false}
                        width='290'
                        height='290'
                      />
                    }
                    <Card.Content>
                      <Card.Header>{authUser.username}</Card.Header>
                      <Card.Meta>
                        {/* {authUser.roles['ADMIN'] ? 'Admin' : 'Authorized User'} */}
                      </Card.Meta>
                      <Card.Description>{authUser.email}</Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <Card.Meta>
                        <Icon name='user' />
                        &nbsp;Change your profile pic
                        <br />
                        <br />
                      </Card.Meta>
                      <FileUpload
                        as='button'
                        fluid
                        attach='bottom'
                        onFileUploaded={this.onFileUploaded}
                        authUser={authUser}
                      />
                    </Card.Content>
                  </Card>
                </Grid.Row>
              </Grid>
            </div>
          )}
        </AuthUserContext.Consumer>
        {/* <MakeAdminPage /> */}
      </React.Fragment>
    );
  }
}

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(withFirebase(AccountPage));
