import React from 'react';
import { withAuthorization } from '../../Session/';
import { AuthUserContext } from '../../Session';

import { Message, Segment, Header, Grid } from 'semantic-ui-react';

const message1 =
  'You have been successfully added to the list of users, however, you must be authorized by an administrator to view restricted content.';
const message2 =
  'A notification email has been sent to an administrator, who will review your request. In the meantime, you may update your account information.';

const SignUpSuccess = (props) => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <Grid textAlign='center'>
        <Grid.Column style={{ maxWidth: 500 }}>
          <Header as='h2' color='blue' textAlign='center'>
            Welcome
          </Header>
          <Segment textAlign='left'>
            <Message
              icon='thumbs up outline'
              header={`Hello, ${authUser.username}`}
              content={message1}
            />
            <Message icon='mail outline' content={message2} size='small' />
          </Segment>
        </Grid.Column>
      </Grid>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default withAuthorization(condition)(SignUpSuccess);
