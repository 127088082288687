import React from 'react';
import { Component } from 'react';
import { Button } from 'semantic-ui-react';

class ExhibitPage extends Component {
	constructor(props) {
		super(props);
		this.iframe = null;
		this.vw = window.innerWidth;
		this.vh = window.innerHeight;
		this.menuHeight = 92;
		this.myElement = null;
		this.state = {
			vw: this.vw,
			vh: this.vh - this.menuHeight,
			fullScreen: false,
		};
	}

	onResize = () => {
		let _vw = window.innerWidth;
		let _vh = window.innerHeight - this.menuHeight;
		this.setState({
			vw: _vw,
			vh: _vh,
		});
	};

	/* Get into full screen */
	goInFullScreen(element) {
		if (element.requestFullscreen) element.requestFullscreen();
		else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
		else if (element.webkitRequestFullscreen)
			element.webkitRequestFullScreen();
		else if (element.msRequestFullscreen) element.msRequestFullScreen();
		this.setState({ fullScreen: true });
	}

	goOutFullScreen() {
		if (document.exitFullscreen) document.exitFullscreen();
		else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
		else if (document.webkitExitFullScreen) document.webkitExitFullScreen();
		else if (document.msExitFullScreen) document.msExitFullScreen();
		this.setState({ fullScreen: false });
	}

	/* Is the display currently full screen or not? */
	isFullScreen() {
		var full_screen_element =
			document.fullscreenElement ||
			document.webkitFullscreenElement ||
			document.mozFullScreenElement ||
			document.msFullscreenElement ||
			null;

		// If no element is in full screen
		if (full_screen_element === null) {
			this.setState({ fullScreen: false });
			return false;
		} else {
			this.setState({ fullScreen: true });
			return true;
		}
	}

	componentDidMount() {
		this.iframe = document.getElementById('exhibit');
		window.addEventListener('resize', this.onResize);

		// Set event listerner for all APIs
		['', 'webkit', 'moz', 'ms'].forEach((prefix) => {
			document.addEventListener(prefix + 'fullscreenchange', (event) => {
				if (document.fullscreenElement) {
					this.iframe.contentWindow.postMessage({ fullScreen: true }, '*');
					// console.log(
					//   `[Parent:] ${document.fullscreenElement.id} entered fullscreen mode.`
					// );
				} else {
					this.iframe.contentWindow.postMessage(
						{ fullScreen: false },
						'*'
					);
					// console.log('[Parent:] exited fullscreen mode.');
				}
			});
		});
	}

	render() {
		const { fullScreen } = this.state;
		return (
			<div
				style={{ backgroundColor: '#000', position: 'relative' }}
				id='element'
				ref={(div) => (this.myElement = div)}>
				<Button
					icon={fullScreen ? 'expand' : 'expand arrows alternate'}
					inverted
					size='small'
					onClick={() => {
						if (!this.isFullScreen()) this.goInFullScreen(this.myElement);
						else this.goOutFullScreen();
					}}
					style={{
						position: 'absolute',
						bottom: 10,
						right: 10,
						opacity: 0.5,
					}}
				/>

				<iframe
					title='BoB Exhibit'
					frameBorder='0'
					id='exhibit'
					src='https://bob.insightvisuals.com/app' // replace with final URL
					width={this.state.vw}
					height={this.state.vh}>
					Browser not compatible
				</iframe>
			</div>
		);
	}
}

export default ExhibitPage;
