import React from 'react';
import { withFirebase } from '../../Firebase';

import { Button } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';

const SignInButton = ({ firebase }) => (
  <div>
    <Button animated floated='right' basic color='blue'>
      <Button.Content visible>Sign In</Button.Content>
      <Button.Content hidden>
        <Icon name='sign in' />
      </Button.Content>
    </Button>
  </div>
);
export default withFirebase(SignInButton);
