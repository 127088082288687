import React from 'react';
import withSelections from 'react-item-select';
import { Table, Checkbox, Label } from 'semantic-ui-react';
import * as ROLES from '../../constants/roles';
import UsersDropDown from './UsersDropDown';

const UsersTable = (props) => {
  const {
    areAllIndeterminate,
    areAllSelected,
    areAnySelected,
    //selectedCount,
    handleSelect,
    handleSelectAll,
    //handleClearAll
    isItemSelected,
    selections,
    users,
    updateRole,
    deleteUsers,
    disableUsers,
    enableUsers,
    resetPassword,
    isExecuting,
  } = props;

  const updateSelectedRole = (role) => {
    updateRole(getSelectedUsers(), role);
  };

  const deleteSelectedUsers = () => {
    deleteUsers(getSelectedUsers());
  };

  const disableSelectedUsers = () => {
    disableUsers(getSelectedUsers());
  };

  const enableSelectedUsers = () => {
    enableUsers(getSelectedUsers());
  };

  const resetSelectedPassword = () => {
    resetPassword(getSelectedUserEmails());
  };

  const getSelectedUsers = () => {
    const seletedUserIds = Object.keys(selections).map((key) => {
      return users[key].id;
    });
    return seletedUserIds.map((index) => {
      let item = users.find((user) => user.id === index);
      return item ? item.uid : 0;
    });
  };

  const getSelectedUserEmails = () => {
    //console.log(selections);
    const seletedUserIds = Object.keys(selections).map((key) => {
      return users[key].id;
    });

    return seletedUserIds.map((index) => {
      let item = users.find((user) => user.id === index);
      return item ? item.email : 0;
    });
  };

  const renderUserRole = (param) => {
    switch (param) {
      case ROLES.ADMIN.role:
        return <Label color={ROLES.ADMIN.color}>ADMIN</Label>;

      case ROLES.MEMBER.role:
        return <Label color={ROLES.MEMBER.color}>MEMBER</Label>;

      case ROLES.USER.role:
        return <Label color={ROLES.USER.color}>USER</Label>;

      default:
        return <Label color={ROLES.USER.color}>USER</Label>;
    }
  };

  return (
    <React.Fragment>
      <Table style={{ maxWidth: '900px', margin: 'auto' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell textAlign='center'>
              <Checkbox
                checked={areAllSelected(users)}
                indeterminate={areAllIndeterminate(users)}
                onChange={() => handleSelectAll(users)}
              />
            </Table.HeaderCell>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell textAlign='center'>Role</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map((user, key) => (
            <Table.Row key={key}>
              <Table.Cell textAlign='center'>
                <Checkbox
                  checked={isItemSelected(key)}
                  onChange={() => handleSelect(key)}
                />
              </Table.Cell>
              <Table.Cell disabled={user.disabled}>{user.uid}</Table.Cell>
              <Table.Cell disabled={user.disabled}>{user.username}</Table.Cell>
              <Table.Cell disabled={user.disabled}>{user.email}</Table.Cell>
              <Table.Cell textAlign='center'>
                <Label.Group size='mini'>
                  {renderUserRole(user.role)}
                </Label.Group>
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan='5'>
              {areAnySelected && (
                <div>
                  <UsersDropDown
                    updateRole={updateSelectedRole}
                    deleteUsers={deleteSelectedUsers}
                    disableUsers={disableSelectedUsers}
                    enableUsers={enableSelectedUsers}
                    resetPassword={resetSelectedPassword}
                    isExecuting={isExecuting}
                  ></UsersDropDown>
                </div>
              )}

              {/* <span
                style={{ visibility: areAnySelected ? 'visible' : 'hidden' }}
              >
                <span style={{ marginRight: '8px' }}>
                  {selectedCount} selected
                </span>
                <Button basic onClick={handleClearAll}>
                  Clear
                </Button>
              </span>
              <span>
                <p>{users.length} Users</p>
              </span> */}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </React.Fragment>
  );
};

export default withSelections(UsersTable);
