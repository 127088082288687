import React, { Component } from 'react';
import { UsersTable } from '../Users';
import { withFirebase } from '../Firebase';
import { MultiMessage } from '../Message';
import { Header, Dimmer, Segment, Loader, Confirm } from 'semantic-ui-react';

class UsersPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      users: [],
      isLoadingUsers: false,
      message: '',
      error: '',
      isExecuting: false,
      showConfirm: false,
      usersToDelete: [],
    };
    this.usersTable = React.createRef();
  }

  clearAllCheckBoxes = () => {
    this.usersTable.current.handleClearAll();
  };

  handleMessageDismiss = () => {
    this.setState({ error: '', message: '' });
  };

  handleResult = (result) => {
    // deselect all
    this.clearAllCheckBoxes();
    this.setState({
      message: result.data.message,
      isExecuting: false,
      isLoadingUsers: false,
    });
  };

  handleError = (error) => {
    this.setState({
      isExecuting: false,
      isLoadingUsers: false,
    });
    const errorCode = `[${error.code}] - ${error}`;
    this.setState({ error: errorCode });
  };

  setExecute = () => {
    this.setState({ isExecuting: true, isLoadingUsers: true });
  };

  /**
   * Update the selected users with role
   */
  updateRole = (users, role) => {
    //console.log('[UsersPage]: Update Role called:', role, users);
    this.setExecute();

    this.props.firebase
      .updateRole(users, role)
      .then(this.handleResult, this.handleError);
  };
  /**
   * Delete the selected users passed to the function
   */
  deleteUsers = (users) => {
    //console.log('[UsersPage]: Delete Users called', users);
    // this.setExecute();
    // this.props.firebase
    //   .deleteUsers(users)
    //   .then(this.handleResult, this.handleError);
    this.setState({ showConfirm: true, usersToDelete: users });
  };

  handleDefinitiveDeletion = () => {
    this.setExecute();
    this.setState({ showConfirm: false });
    this.props.firebase
      .deleteUsers(this.state.usersToDelete)
      .then(this.handleResult, this.handleError);
  };
  onCloseConfirm = () => {
    //console.log('closing modal');
    this.setState({ usersToDelete: [], showConfirm: false });
  };

  /**
   * Disable the selected users passed to the function
   */

  disableUsers = (users) => {
    //console.log('[UsersPage]: Disable Users called', users);
    this.setExecute();

    this.props.firebase
      .disableUsers(users)
      .then(this.handleResult, this.handleError);
  };
  enableUsers = (users) => {
    //console.log('[UsersPage]: Enable Users called', users);
    this.setExecute();

    this.props.firebase
      .enableUsers(users)
      .then(this.handleResult, this.handleError);
  };

  resetPassword = (users) => {
    //console.log('[UsersPage]: Reset Paswsword called', users);
    this.setExecute();

    this.props.firebase
      .doUsersPasswordReset(users)
      .then(this.handleResult, this.handleError);
  };

  componentDidMount() {
    this.setState({ isLoadingUsers: true });
    this.props.firebase.users().on('value', (snapshot) => {
      const usersObject = snapshot.val();
      // return array of objects
      const usersList = Object.keys(usersObject).map((uid, key) => {
        return {
          ...usersObject[uid],
          uid: uid,
          id: key,
        };
      });
      this.setState({ users: usersList, isLoadingUsers: false });
      //console.log(usersList);
    });
  }

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  render() {
    const {
      users,
      message,
      error,
      isExecuting,
      isLoadingUsers,
      showConfirm,
      usersToDelete,
    } = this.state;
    return (
      <div style={{ maxWidth: '900px', margin: 'auto' }}>
        <Header as='h1'>Users List</Header>
        <Dimmer.Dimmable as={Segment} dimmed={isLoadingUsers}>
          <Dimmer active={isLoadingUsers} inverted>
            <Loader size='large'></Loader>
          </Dimmer>
          <UsersTable
            ref={this.usersTable}
            users={users}
            updateRole={this.updateRole}
            deleteUsers={this.deleteUsers}
            resetPassword={this.resetPassword}
            enableUsers={this.enableUsers}
            disableUsers={this.disableUsers}
            isExecuting={isExecuting}
          />
        </Dimmer.Dimmable>
        <MultiMessage
          message={message}
          error={error}
          handleMessageDismiss={this.handleMessageDismiss}
        />
        <Confirm
          open={showConfirm}
          size='tiny'
          onCancel={this.onCloseConfirm}
          onConfirm={this.handleDefinitiveDeletion}
          header='Account Deletion'
          content={`Are you sure you want to delete the ${
            usersToDelete.length
          } selected user${usersToDelete.length > 1 ? 's' : ''}?`}
        />
      </div>
    );
  }
}

export default withFirebase(UsersPage);
