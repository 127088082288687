import React, { Component } from 'react';
import { Dropdown, Button, Icon } from 'semantic-ui-react';

import * as ROLES from '../../constants/roles';

class UsersDropDown extends Component {
  dropdownRef = React.createRef();
  constructor(props) {
    super(props);

    this.state = {
      value: null,
    };
  }

  handleItemClick = (event, value) => {
    // Need to save the choice on parent
    this.dropdownRef.current.handleItemClick(event, value);
  };

  handleChange = (e, { value }) => {
    this.setState({ value });
  };

  handleClose = () => {
    //console.log('Close called');
  };

  onActionExecute = (event, value) => {
    switch (this.state.value) {
      case 'Make Admin':
        this.props.updateRole(ROLES.ADMIN.role);
        //console.log('Make Admin Called');
        break;
      case 'Make User':
        this.props.updateRole(ROLES.USER.role);
        //console.log('Make User Called');
        break;
      case 'Make Member':
        this.props.updateRole(ROLES.MEMBER.role);
        //console.log('Make Member Called');
        break;
      case 'Delete':
        this.props.deleteUsers();
        //console.log('Delete Called');
        break;
      case 'Disable':
        this.props.disableUsers();
        //console.log('Disable Called');
        break;
      case 'Enable':
        this.props.enableUsers();
        //console.log('Disable Called');
        break;
      case 'Reset Password':
        this.props.resetPassword();
        //console.log('Reset Password Called');
        break;
      default:
      //console.log('Invalid Called');
    }
  };

  render() {
    const menuOptions = [
      {
        key: 'Make Admin',
        text: 'Make Admin',
        value: 'Make Admin',
        icon: 'certificate',
      },
      {
        key: 'Make Member',
        text: 'Make Member',
        value: 'Make Member',
        icon: 'group',
      },
      {
        key: 'Make User',
        text: 'Make User',
        value: 'Make User',
        icon: 'user',
      },
      {
        key: 'Delete',
        text: 'Delete',
        value: 'Delete',
        icon: 'close',
      },
      {
        key: 'Disable',
        text: 'Disable',
        value: 'Disable',
        icon: 'eye slash outline',
      },
      {
        key: 'Enable',
        text: 'Enable',
        value: 'Enable',
        icon: 'eye',
      },
      {
        key: 'Reset Password',
        text: 'Reset Password',
        value: 'Reset Password',
        icon: 'user secret',
      },
    ];

    return (
      <div>
        <Icon name='tasks' />
        With selected items:{' '}
        <Dropdown
          ref={this.dropdownRef}
          placeholder='Perform action...'
          onChange={this.handleChange}
          onClose={this.handleClose}
          clearable={true}
          value={this.state.value}
          text={this.state.value}
          inline
          floating
        >
          <Dropdown.Menu>
            <Dropdown.Header>Modify User Role</Dropdown.Header>
            {menuOptions.map((option, key) =>
              key === 3 ? (
                <React.Fragment key={option.value}>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    active={option.value === this.state.value}
                    {...option}
                    onClick={this.handleItemClick}
                  />
                </React.Fragment>
              ) : (
                <React.Fragment key={option.value}>
                  <Dropdown.Item
                    active={option.value === this.state.value}
                    {...option}
                    onClick={this.handleItemClick}
                  />
                </React.Fragment>
              )
            )}
          </Dropdown.Menu>
        </Dropdown>
        {this.state.value && (
          <Button
            color='blue'
            size='mini'
            onClick={this.onActionExecute}
            loading={this.props.isExecuting}
          >
            APPLY
          </Button>
        )}
      </div>
    );
  }
}

export default UsersDropDown;
