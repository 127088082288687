import React from 'react';
import { withFirebase } from '../../Firebase';
import { Button } from 'semantic-ui-react';
import { Icon } from 'semantic-ui-react';

const SignOutButton = ({ firebase }) => (
  <div>
    <Button
      animated
      floated='right'
      basic
      color='blue'
      onClick={firebase.doSignOut}
    >
      <Button.Content visible>Sign Out</Button.Content>
      <Button.Content hidden>
        <Icon name='sign out' />
      </Button.Content>
    </Button>
  </div>
);
export default withFirebase(SignOutButton);
