import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import { withAuthorization } from '../Session';

import { UserItem } from '../Users';
import MakeAdminPage from './MakeAdmin';
import RevokeAdminPage from './RevokeAdmin';
import { UsersPage } from '../Users';
//import MyTable from '../Table';

import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

const AdminPage = () => (
  <div style={{ margin: '40px' }}>
    <Switch>
      <Route exact path={ROUTES.ADMIN} component={UsersPage} />
      <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItem} />
    </Switch>
  </div>
);

const condition = (authUser) =>
  !!authUser && authUser.role === ROLES.ADMIN.role;

export default compose(withAuthorization(condition))(AdminPage);
export { MakeAdminPage, RevokeAdminPage };
