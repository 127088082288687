import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

import * as ROLES from '../../constants/roles';

import {
  Table,
  Message,
  Label,
  Checkbox,
  Dropdown,
  Icon,
  Button,
  Confirm,
  Segment,
  Dimmer,
  Loader,
} from 'semantic-ui-react';

import update from 'immutability-helper';

const bulkActions = [
  {
    key: 'Delete',
    text: 'Delete',
    value: 'Delete',
    icon: 'close',
  },
  {
    key: 'Disable',
    text: 'Disable',
    value: 'Disable',
    icon: 'eye slash outline',
  },
  {
    key: 'Make Admin',
    text: 'Make Admin',
    value: 'Make Admin',
    icon: 'certificate',
  },
  {
    key: 'Make Member',
    text: 'Make Member',
    value: 'Make Member',
    icon: 'group',
  },
  {
    key: 'Make User',
    text: 'Make User',
    value: 'Make User',
    icon: 'user',
  },
  {
    key: 'Reset Password',
    text: 'Reset Password',
    value: 'Reset Password',
    icon: 'user secret',
  },
];

class UserList extends Component {
  dropdownRef = React.createRef();
  // checkBoxRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      usersLoading: false,
      executing: false,
      users: [],
      selected: false,
      selectedUsers: [],
      error: null,
      menuOptions: bulkActions,
      showConfirm: false,
      message: '',
      selections: {},
      checkBoxRefs: {},
    };
  }

  handleSelect = (id) => {
    this.setState((prevState) => {
      if (!!prevState.selections[id]) {
        return update(prevState, {
          selections: { $unset: [id] },
        });
      }
      return update(prevState, {
        selections: { [id]: { $set: true } },
      });
    });
    console.log(this.state.selections);
  };

  /**
   * Select all is inverting the selections
   * based on what is selected
   * it really is not meant to handle the entire group
   */

  // addCheckBoxRef = (checkbox) => {
  //   //this.checkBoxRefs.push(checkbox);
  //   this.setState((prevState) => {
  //     return update(prevState, {
  //       checkBoxRefs: { [checkbox.uid]: { $set: true } },
  //     });
  //   });
  //   console.log(this.state.checkBoxRefs);
  // };

  // createCheckBox = (id) => {
  //   // this.setState((prevState) => {
  //   //   return update(prevState, {
  //   //     selections: { [id]: { $set: false } },
  //   //   });
  //   // });
  //   const ref = React.createRef();
  //   this.checkBoxRefs.push(ref);
  //   console.log(this.checkBoxRefs);
  //   return (
  //     <Checkbox
  //       ref={ref}
  //       key={id}
  //       checked={this.isItemSelected(id)}
  //       onChange={() => this.handleSelect(id)}
  //     />
  //   );
  // };

  /**
   * <Checkbox
                      checked={this.isItemSelected(user.uid)}
                      onChange={() => this.handleSelect(user.uid)}
                      //onChange={this.onElementChecked}
                      user={user.email}
                      id={user.uid}
                      ref={this.checkBoxRef}
                    />
   */

  selectAllCheckboxes = (isSelected) => {
    // Object.keys(this.state.checkBoxRefs).forEach((checkbox) => {
    //   console.log(checkbox);
    //   this.setState({
    //     selections: {
    //       [checkbox]: true,
    //     },
    //   });
    // });
    Object.keys(this.state.checkBoxRefs).forEach((checkbox) => {
      this.setState((prevState) => ({
        checkBoxRefs: {
          ...prevState.checkBoxRefs,
          [checkbox]: (
            <Checkbox
              checked={this.state.selections[checkbox]}
              onChange={() => this.handleSelect(checkbox)}
              key={checkbox}
            />
          ),
        },
        selections: {
          ...prevState.selections,
          [checkbox]: true,
        },
      }));
    });
    console.log(this.state.selections);
  };

  componentDidMount() {
    this.setState({ usersLoading: true });

    this.props.firebase.users().on(
      'value',
      (snapshot) => {
        //console.log(snapshot.val());

        const usersObject = snapshot.val();

        const usersList = Object.keys(usersObject).map((key) => {
          this.setState((prevState) => {
            return update(prevState, {
              checkBoxRefs: {
                [key]: {
                  $set: (
                    <Checkbox
                      checked={this.state.selections[key]}
                      onChange={() => this.handleSelect(key)}
                      key={key}
                    />
                  ),
                },
              },
            });
          });
          //console.log(this.state.checkBoxRefs);
          return {
            ...usersObject[key],
            uid: key,
          };
        });

        this.setState({
          users: usersList,
          usersLoading: false,
        });
      },
      (error) => {
        const errorCode = `[${error.code}] - ${error}`;
        this.setState({ error: errorCode, usersLoading: false });
      }
    );
  }

  manageSeletecUsers(element) {
    let selectedUsers = this.state.selectedUsers;

    if (element.checked === true) {
      selectedUsers.push(element);
      this.setState({ selectedUsers: selectedUsers });
    } else {
      selectedUsers = selectedUsers.filter((obj) => {
        return obj.id !== element.id;
      });
      this.setState({ selectedUsers: selectedUsers });
    }

    //let selected = selectedUsers.length > 0 ? true : false;
    //this.setState({ selected: selected });

    //console.log(selectedUsers);
  }

  handleItemClick = (event, value) => {
    this.dropdownRef.current.handleItemClick(event, value);
    //console.log(value.value);
  };

  resetCheckboxes = () => {
    //this.checkBoxRef.current.state.checked = false;
    //console.log(this.checkBoxRefs);
    this.checkBoxRefs.forEach((checkbox) => {
      checkbox.state.checked = false;
    });
  };

  handleChange = (e, { value }) => {
    this.setState({ value });
  };

  componentWillUnmount() {
    this.props.firebase.users().off();
  }

  onElementChecked = (event, value) => {
    this.manageSeletecUsers(value);
  };

  onExecute = (event, value) => {
    switch (this.state.value) {
      case 'Delete':
        console.log('Delete Called');
        this.deleteUsers();
        break;
      case 'Disable':
        console.log('Disable Called');
        break;
      case 'Make Admin':
        this.updateRole(ROLES.ADMIN.role);
        console.log('Make Admin Called');
        break;
      case 'Make User':
        console.log('Make User Called');
        this.updateRole(ROLES.USER.role);
        break;
      case 'Make Member':
        console.log('Make Member Called');
        this.updateRole(ROLES.MEMBER.role);
        break;
      case 'Reset Password':
        console.log('Reset Password Called');
        break;
      default:
        console.log('Invalid Called');
    }
  };

  deleteUsers = () => {
    this.onOpenConfirm();
  };

  updateRole = (role) => {
    this.setExecute();

    const users = this.state.selectedUsers;

    this.props.firebase.updateRole(users, role).then(
      (result) => {
        this.resetTable();
        this.updateResults(result);
      },
      (error) => this.setState({ error: error })
    );
  };

  setExecute = () => {
    this.setState({ executing: true, usersLoading: true });
  };

  updateResults = (result) => {
    this.setState({
      message: result.data.message,
      executing: false,
      usersLoading: false,
    });
    setTimeout(() => this.setState({ message: '' }), 3000);
  };

  resetTable = () => {
    this.resetCheckboxes();
    this.setState({
      selectedUsers: [],
      //selected: '',
      value: '',
    });
  };

  onOpenConfirm = () => {
    this.setState({ showConfirm: true });
  };
  onCloseConfirm = () => {
    this.setState({ showConfirm: false });
  };

  handleDefinitiveDeletion = () => {
    this.setState({ showConfirm: false });

    const users = this.state.selectedUsers;
    //console.log(`${users[i].id} with ${users[i].user} will be deleted`);
    this.props.firebase.deleteUsers(users).then(
      (result) => {
        this.setState({
          selectedUsers: [],
          //selected: '',
          value: '',
        });
        this.updateResults(result);
      },
      (error) => this.setState({ error: error })
    );
  };

  handleErrorDismiss = () => {
    this.setState({ error: '' });
  };

  renderUserRole = (param) => {
    switch (param) {
      case ROLES.ADMIN.role:
        return <Label color={ROLES.ADMIN.color}>ADMIN</Label>;

      case ROLES.MEMBER.role:
        return <Label color={ROLES.MEMBER.color}>MEMBER</Label>;

      case ROLES.USER.role:
        return <Label color={ROLES.USER.color}>USER</Label>;

      default:
        return <Label color={ROLES.USER.color}>USER</Label>;
    }
  };

  render() {
    const {
      usersLoading,
      executing,
      users,
      selected,
      error,
      selectedUsers,
      menuOptions,
      showConfirm,
      message,
    } = this.state;

    return (
      <div style={{ margin: '40px' }}>
        <h2>Users</h2>
        <Dimmer.Dimmable as={Segment} dimmed={usersLoading}>
          <Dimmer active={usersLoading} inverted>
            <Loader size='large'>Loading</Loader>
          </Dimmer>

          <Table size='small' striped>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>
                  <strong></strong>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <strong>ID</strong>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <strong>E-mail</strong>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <strong>Username</strong>
                </Table.HeaderCell>
                <Table.HeaderCell textAlign='center'>
                  <strong>Role</strong>
                </Table.HeaderCell>
                <Table.HeaderCell>
                  <strong>Detail</strong>
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map((user, key) => (
                <Table.Row key={user.uid}>
                  <Table.Cell>{this.state.checkBoxRefs[user.uid]}</Table.Cell>
                  <Table.Cell>{user.uid}</Table.Cell>

                  <Table.Cell>{user.email}</Table.Cell>

                  <Table.Cell>{user.username}</Table.Cell>

                  <Table.Cell textAlign='center'>
                    <Label.Group size='mini'>
                      {this.renderUserRole(user.role)}
                    </Label.Group>
                  </Table.Cell>

                  <Table.Cell>
                    <span>
                      <Link
                        to={{
                          pathname: `${ROUTES.ADMIN}/${user.uid}`,
                          state: { user },
                        }}
                      >
                        Details
                      </Link>
                    </span>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='6'>
                  {selected && (
                    <div>
                      <Icon name='tasks' />
                      With selected items:{' '}
                      <Dropdown
                        ref={this.dropdownRef}
                        placeholder='Perform action...'
                        onChange={this.handleChange}
                        onClose={this.handleClose}
                        clearable={true}
                        value={this.state.value}
                        text={this.state.value}
                        inline
                        floating
                      >
                        <Dropdown.Menu>
                          {menuOptions.map((option) => (
                            <Dropdown.Item
                              active={option.value === this.state.value}
                              key={option.value}
                              {...option}
                              onClick={this.handleItemClick}
                            />
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                      {this.state.value && (
                        <Button
                          color='blue'
                          size='mini'
                          onClick={this.onExecute}
                          loading={executing}
                        >
                          APPLY
                        </Button>
                      )}
                    </div>
                  )}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Dimmer.Dimmable>
        {error && (
          <Message
            color='red'
            icon='hand paper outline'
            header='Error'
            content={error}
            onDismiss={this.handleErrorDismiss}
          />
        )}
        {message && (
          <Message
            color='green'
            icon='thumbs up outline'
            header='Success!'
            content={message}
          />
        )}

        <Confirm
          open={showConfirm}
          size='tiny'
          onCancel={this.onCloseConfirm}
          onConfirm={this.handleDefinitiveDeletion}
          header='Account Deletion'
          content={`Are you sure you want to delete the ${
            selectedUsers.length
          } selected user${selectedUsers.length > 1 ? 's' : ''}?`}
        />
        <Button
          color='blue'
          content='select all'
          onClick={() => this.selectAllCheckboxes(true)}
        />
      </div>
    );
  }
}

export default withFirebase(UserList);
