import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { AuthUserContext } from '../Session';

import SignOutButton from '../Authentication/SignOut';
import { SignInButton } from '../Authentication/SignIn';

import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';

import logo from '../../assets/images/square_logo.svg';

import { Menu, Segment } from 'semantic-ui-react';

class Navigation extends Component {
  state = { activeItem: 'landing' };

  handleItemClick = (event, { name }) => {
    this.setState({ activeItem: name });
  };

  render() {
    const { activeItem } = this.state;
    const handleItemClick = this.handleItemClick;

    return (
      <AuthUserContext.Consumer>
        {(authUser) =>
          authUser ? (
            <NavigationAuth
              authUser={authUser}
              activeItem={activeItem}
              handleItemClick={handleItemClick}
            />
          ) : (
            <NavigationNonAuth />
          )
        }
      </AuthUserContext.Consumer>
    );
  }
}

const NavigationAuth = (props) => (
  <Segment style={{ zIndex: 1000, marginBottom: 0 }}>
    <Menu secondary>
      <Menu.Item as={Link} to={ROUTES.LANDING}>
        <img src={logo} alt='logo' style={{ borderRadius: '50%' }} />
      </Menu.Item>

      <Menu.Item
        as={Link}
        to={ROUTES.ACCOUNT}
        name='account'
        active={props.activeItem === 'account'}
        onClick={props.handleItemClick}
        color='blue'
      />
      {(props.authUser.role === ROLES.MEMBER.role ||
        props.authUser.role === ROLES.ADMIN.role) && (
        <React.Fragment>
          <Menu.Item
            as={Link}
            to={ROUTES.EXHIBIT}
            name='exhibit'
            active={props.activeItem === 'exhibit'}
            onClick={props.handleItemClick}
            color='blue'
          />
        </React.Fragment>
      )}

      {props.authUser.role === ROLES.ADMIN.role && (
        <React.Fragment>
          <Menu.Item
            as={Link}
            to={ROUTES.ADMIN}
            name='admin'
            active={props.activeItem === 'admin'}
            onClick={props.handleItemClick}
            color='blue'
          />
        </React.Fragment>
      )}

      <Menu.Item position='right'>
        <SignOutButton />
      </Menu.Item>
    </Menu>
  </Segment>
);

const NavigationNonAuth = (props) => (
  <Segment>
    <Menu secondary>
      <Menu.Item as={Link} to={ROUTES.LANDING}>
        <img src={logo} alt='logo' style={{ borderRadius: '50%' }} />
      </Menu.Item>
      <Menu.Item position='right' as={Link} to={ROUTES.SIGN_IN}>
        <SignInButton />
      </Menu.Item>
    </Menu>
  </Segment>
);

export default Navigation;
