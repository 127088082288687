import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase';
import { AuthUserContext, withAuthentication } from '../../Session';
import SignUpSuccess from '../SignUp/SignUpSuccess';

import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';

import {
  Button,
  Form,
  Grid,
  Header,
  Message,
  Segment,
} from 'semantic-ui-react';

const SignUpPage = () => (
  <div>
    <SignUpForm />
  </div>
);

const INITIAL_STATE = {
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  role: ROLES.USER.role,
  error: null,
  success: false,
};

class SignUpFormBase extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { username, email, passwordOne, role } = this.state;

    this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then((authUser) => {
        // Create user in Firebase realtime database
        //console.log('[authUser]', authUser.user.isAdmin);
        if (authUser.user.isAdmin) {
          this.setState({ role: ROLES.ADMIN.role });
        }
        return this.props.firebase.user(authUser.user.uid).set({
          username,
          email,
          role,
        });
      })
      .then((authUser) => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.push(ROUTES.SIGN_UP_SUCCESS);
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { username, email, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      username === '';

    return (
      <AuthUserContext.Consumer>
        {(authUser) => (
          <Grid textAlign='center' verticalAlign='top'>
            <Grid.Column style={{ maxWidth: 450 }}>
              <Header as='h2' color='blue' textAlign='center'>
                Sign-up
              </Header>
              <Form size='large' onSubmit={this.onSubmit}>
                <Segment>
                  <Form.Input
                    fluid
                    icon='user'
                    iconPosition='left'
                    name='username'
                    value={username}
                    onChange={this.onChange}
                    type='text'
                    placeholder='Name'
                  />
                  <Form.Input
                    fluid
                    icon='mail outline'
                    iconPosition='left'
                    name='email'
                    value={email}
                    onChange={this.onChange}
                    type='text'
                    placeholder='Email Address'
                  />
                  <Form.Input
                    fluid
                    icon='user secret'
                    iconPosition='left'
                    name='passwordOne'
                    value={passwordOne}
                    onChange={this.onChange}
                    type='password'
                    placeholder='Password'
                  />
                  <Form.Input
                    fluid
                    icon='user secret'
                    iconPosition='left'
                    name='passwordTwo'
                    value={passwordTwo}
                    onChange={this.onChange}
                    type='password'
                    placeholder='Confirm Password'
                  />
                </Segment>
                <Button fluid color='blue' disabled={isInvalid} type='submit'>
                  Sign Up
                </Button>
              </Form>
              {error && <Message>{error.message}</Message>}
            </Grid.Column>
          </Grid>
        )}
      </AuthUserContext.Consumer>
    );
  }
}

const SignUpLink = () => (
  <p>
    Don't have an account? <Link to={ROUTES.SIGN_UP}>Sign Up</Link>
  </p>
);
const SignUpForm = compose(
  withRouter,
  withFirebase,
  withAuthentication
)(SignUpFormBase);

export default SignUpPage;

export { SignUpForm, SignUpLink, SignUpSuccess };
