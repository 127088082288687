import React from 'react';
import { Message } from 'semantic-ui-react';

const MultiMessage = (props) => {
  const { message, error, handleMessageDismiss } = props;

  return (
    <React.Fragment>
      {message && (
        <Message
          color='green'
          icon='thumbs up outline'
          header='Success'
          content={message}
          onDismiss={handleMessageDismiss}
        />
      )}
      {error && (
        <Message
          color='red'
          icon='hand paper outline'
          header='Error'
          content={error}
          onDismiss={handleMessageDismiss}
        />
      )}
    </React.Fragment>
  );
};

export default MultiMessage;
