import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

import { Table, Button, Message } from 'semantic-ui-react';

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      success: false,
      ...props.location.state,
    };
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    console.log('User Item', this.state.user);

    this.props.firebase
      .user(this.props.match.params.id)
      .on('value', (snapshot) => {
        this.setState({
          user: snapshot.val(),
          loading: false,
        });
      });
  }

  componentWillUnmount() {
    this.props.firebase.user(this.props.match.params.id).off();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email).then(() => {
      this.setState({ success: true });
      setTimeout(() => this.setState({ success: false }), 3000);
    });
  };

  render() {
    const { user, loading } = this.state;

    return (
      <div>
        {loading && <div>Loading... </div>}
        {user && (
          <Table>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>E-Mail</Table.HeaderCell>
                <Table.HeaderCell>Username</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{user.uid}</Table.Cell>
                <Table.Cell>{user.email}</Table.Cell>
                <Table.Cell>{user.username}</Table.Cell>
              </Table.Row>
            </Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan='3'>
                  <Button
                    type='button'
                    floated='right'
                    onClick={this.onSendPasswordResetEmail}
                  >
                    Send Password Reset
                  </Button>
                </Table.HeaderCell>
              </Table.Row>
              <Table.Row>
                {this.state.success && (
                  <Table.HeaderCell colSpan='3'>
                    <Message positive>
                      <Message.Header>Status</Message.Header>
                      <p>
                        A password email has been sent to {user.username} at{' '}
                        {user.email}
                      </p>
                    </Message>
                  </Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Footer>
          </Table>
        )}
      </div>
    );
  }
}

export default withFirebase(UserItem);
