import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database'; // Use Firebase Realtime Database
import 'firebase/storage'; // User Firebase Cloudstorage
import 'firebase/firebase-functions'; // Access to Cloud Functions
import * as ROLES from '../../constants/roles';

const config = {
  apiKey: process.env.REACT_APP_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_POROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */
    this.serverValue = app.database.ServerValue;

    /* Firebase APIs */
    this.auth = app.auth();
    this.db = app.database();
    this.store = app.storage();
    this.functions = app.functions();
  }
  /**
   * Cloud Functions
   */

  // Update Role (Cloud Function)
  updateRole = (users, role) => {
    const updateRole = this.functions.httpsCallable('updateRole');
    return updateRole({ users: users, role: role });
  };

  // Delete Users (Cloud Function)
  deleteUsers = (users) => {
    const deleteUsers = this.functions.httpsCallable('deleteUsers');
    return deleteUsers({ users: users });
  };

  // Disable Users (Cloud Function)
  disableUsers = (users) => {
    const disableUsers = this.functions.httpsCallable('disableUsers');
    return disableUsers({ users: users });
  };

  // Enable Users (Cloud Function)
  enableUsers = (users) => {
    const enableUsers = this.functions.httpsCallable('enableUsers');
    return enableUsers({ users: users });
  };

  // End Cloud Functions

  // Reset Password
  doUsersPasswordReset = (emails) => {
    let queue = [];

    emails.forEach((email) => {
      //console.log(email);
      let promise = this.doPasswordReset(email);
      queue.push(promise);
    });

    return Promise.all(queue)
      .then(() => {
        return { data: { message: 'Password reset email sent' } };
      })
      .catch((error) => {
        return { error: `An error occurred: ${error}` };
      });
  };

  // Make Admin (Cloud Function)
  makeAdmin = (email) => {
    const addAdminRole = this.functions.httpsCallable('addAdminRole');
    return addAdminRole({ email: email });
  };

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  // doCreateUser = (email, password) =>
  //   this.auth.createUser({
  //     email: email,
  //     emailVerified: false,
  //     phoneNumber: '+11234567890',
  //     password: password,
  //     displayName: 'John Doe',
  //     photoURL: 'http://www.example.com/12345678/photo.png',
  //     disabled: false,
  //   });

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  // *** Clone User into Realtime DB ***
  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged((authUser) => {
      if (authUser) {
        //console.log('[AuthStateChanged]:', authUser);
        authUser.getIdTokenResult().then((idTokenResult) => {
          const role = idTokenResult.claims.role;
          authUser.role = role;
          //console.log('[Role]:', idTokenResult.claims.role);
          this.user(authUser.uid)
            .once('value')
            .then((snapshot) => {
              // Get user from realtime DB
              const dbUser = snapshot.val();
              //console.log('[RealtimeDB]', dbUser);
              // Set default if no role found
              if (!dbUser.role) {
                dbUser.role = ROLES.USER.role;
              }
              //console.log(authUser);
              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                email: authUser.email,
                role: authUser.role,
                disabled: authUser.disabled,
                emailVerified: authUser.emailVerified,
                ...dbUser,
              };
              //console.log(authUser);
              next(authUser);
            });
        });
      } else {
        fallback();
      }
    });

  // *** User API ***
  user = (uid) => this.db.ref(`users/${uid}`);
  users = () => this.db.ref('users');

  // *** Message API ***
  message = (uid) => this.db.ref(`messages/${uid}`);
  messages = (uid) => this.db.ref('messages');

  // *** File Storage API ***
  image = (filename) => this.store.ref(`images/${filename}`);
  images = (filename) => this.store.ref('images');
}

export default Firebase;
