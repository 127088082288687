import React from 'react';

import { Grid, Image } from 'semantic-ui-react';
import HistoricBattleIllustration from '../../assets/images/BattleOfBrooklyn.gif';
import BoBTypeface from '../../assets/images/BattleOfBrooklyn.svg';

const Landing = () => (
  <Grid centered columns={2} style={{ backgroundColor: 'whitesmoke' }}>
    <Grid.Column>
      <Image src={BoBTypeface} alt='Title Lettering' />
      <Image src={HistoricBattleIllustration} alt='Battle Image' />
    </Grid.Column>
  </Grid>
);

export default Landing;
